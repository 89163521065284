import { useRef, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

/* MATERIAL TAILWIND COMPONENTS */
import { Card, CardBody } from "@material-tailwind/react";

/* CHARTS */
import {
  NetProfitLossGraphLabels,
  NetDailyBarGraph,
} from "../../components/chart";

/* IMAGES */
import Logo from "../../assets/images/logo.webp";

import { getTransactionsAPI, getUserTransactionGraph } from "../../service/api";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Reports = () => {
  const [totalPL, setTotalPL] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState([]);
  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  const [netProfitLossGraphLabelsData, setNetProfitLossGraphLabelsData] = useState([]);
  const [netDailyBarGraphData, setNetDailyBarGraphData] = useState([]);


  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /* GET TRANSACTIONS FUNCTION */
  const fetchTransactionsDataFunction = () => {

    getTransactionsAPI({ pagination: false, isOpen: false })
      .then((res) => {
        console.log("res==>", res?.data?.data);
        // setTransactionData(res?.data?.data?.docs)
        // setTotalDocs(res?.data?.data?.totalDocs)
        // setTotalPages(res?.data?.data?.totalPages)
        setTotalTransactions(res?.data?.data)
        let cumulativeCount = [
          { algo: 1, count: 0 },
          { algo: 2, count: 0 },
          { algo: 3, count: 0 },
          { algo: 4, count: 0 },
          { algo: 5, count: 0 },
          { algo: 6, count: 0 }
        ];
        let totalPLCombined = 0;
        res?.data?.data?.forEach((ele) => {

          let count = cumulativeCount?.find((e) => e?.algo === ele?.algo)?.count;

          if (ele.entryTime !== ele.exitTime) {
            if (ele.profit) {
              totalPLCombined = totalPLCombined + ele.profit;
              cumulativeCount.find((el) => el?.algo === ele?.algo).count = count + ele.profit;
            }
          }
        });


        cumulativeCount.totalPLCombined = totalPLCombined
        setTotalPL(cumulativeCount);
      })
      .catch((e) => {
        console.log("e==>", e);
      });

    getUserTransactionGraph()
      .then((res) => {
        if (res?.status) {
          let tempArrayCumulative = []
          let tempArrayDaily = []
          let cumulativePrice = 0
          res?.data?.sort(function (a, b) { return new Date(a?._id) - new Date(b?._id) })?.forEach((element, index) => {
            tempArrayDaily.push([element?._id, element?.count])
            // tempArrayDaily.push([new Date(element?._id),tempArrayDaily[index - 1][0][1] + element?.count])

            cumulativePrice += element?.count
            tempArrayCumulative.push([element?._id, cumulativePrice])
          });
          setNetProfitLossGraphLabelsData(tempArrayCumulative)
          setNetDailyBarGraphData(tempArrayDaily)


        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };


  useEffect(() => {
    fetchTransactionsDataFunction();
  }, []);

  /* STRATEGY CARD */
  const StrategyCard = (props) => {
    return (
      <div className="bg-white border border-[#E5E5E5] rounded-lg px-5 py-3 shadow-md mt-5 first:mt-0">
        <div className="grid grid-cols-6 gap-5 items-center">
          <div className="col-span-3">
            <div className="flex items-center gap-3">
              {/* <div className="w-10 h-10 rounded-full bg-header bg-opacity-5 flex items-center justify-center">
                <img
                  src={Logo}
                  className="w-5 h-5 object-cover"
                  alt="Algosquant"
                />
              </div> */}
              <h5 className="text-lg font-nunito-bold text-textBlack tracking-tight">
                {props.name}
              </h5>
            </div>
          </div>
          <div className="col-span-1">
            <h5 className="text-sm font-nunito-regular text-black tracking-tight">
              Deployed on
              <span className="font-nunito-bold block mt-1 text-base">
                {props.date}
              </span>
            </h5>
          </div>
          {/* <div className="col-span-1">
            <h5 className="text-sm font-nunito-regular text-black tracking-tight">
              Total capital deployed
              <span className="font-nunito-bold block mt-1 text-base">
                {props.capital === "-" ? "-" : "₹" + props.capital}
              </span>
            </h5>
          </div> */}
          <div className="col-span-1">
            <h5 className="text-sm font-nunito-regular text-black tracking-tight">
              No. of contracts
              <span className="font-nunito-bold block mt-1 text-base">
                {props.contracts}
              </span>
            </h5>
          </div>
          <div className="col-span-1">
            <div className="w-full rounded-lg bg-white border border-cardBorder px-4 py-2">
              <h5 className="text-sm font-nunito-medium text-black tracking-tight leading-none">
                Strategy PNL:{" "}
                <span className={`text-base font-nunito-bold  block mt-2 ${props?.plAmount?.toFixed(2) >= 0 ? "text-green-500" : "text-red-500"}`}>
                  ₹{props?.plAmount?.toFixed(2)}
                  {/* ({props.plPercentage}%) */}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="py-12">
      <div className="container">
        {/* GREETING */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight">
              Reports
            </h1>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Find the performance of your strategies and trades.
            </p>
          </div>
        </div>

        {/* REPORTS */}
        <Card className="bg-white border border-cardBorder rounded-lg shadow-md mt-10">
          <CardBody className="p-0">
            {/* <div className="px-5 py-3 border-b border-cardBorder flex items-center justify-between"> */}
              {/* <div className="flex items-center gap-5">
                <div className="w-12 h-12 rounded-full bg-header bg-opacity-5 flex items-center justify-center">
                  <img
                    src={Logo}
                    className="w-6 h-6 object-cover"
                    alt="Algosquant"
                  />
                </div>
                <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight">
                  Portfolio Performance
                </h5>
              </div> */}
              {/* <div className="relative leading-none">
                <div ref={domNode} className="form-group">
                  <input
                    type="text"
                    name="dateRange"
                    value={`${startDate} - ${endDate}`}
                    onClick={() => setDatepicker(!datepicker)}
                    className="w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Date range"
                  />
                </div>
                {datepicker && (
                  <div
                    ref={domNode}
                    className="absolute top-[8vh] bottom-0 transition-all duration-300 ease-linear z-50"
                  >
                    <DateRangePicker
                      ranges={[selectionRange]}
                      dragSelectionEnabled
                      showPreview
                      onChange={(e) => {
                        setStartDate(
                          moment(e.selection.startDate).format("DD/MM/YYYY")
                        );
                        setEndDate(
                          moment(e.selection.endDate).format("DD/MM/YYYY")
                        );
                      }}
                      className="shadow-xl font-nunito-medium rounded-xl border border-cardBorder cursor-pointer"
                    />
                  </div>
                )}
              </div> */}
            {/* </div> */}
            <div className="p-5">
              {/* STRATEGY DETAILS */}
              <div className="bg-[#F5F5F5] border border-[#E5E5E5] rounded-lg px-5 py-3">
                <div className="grid grid-cols-4 gap-5 items-center">
                  <div className="col-span-3">
                    <div className="grid grid-cols-3 gap-5">
                      <div className="col-span-1">
                        <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                          Deployed on
                          <span className="font-nunito-bold block mt-2 text-lg">
                            {/* 26 Dec, 2023 */}-
                          </span>
                        </h5>
                      </div>
                      <div className="col-span-1">
                        <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                          Total capital deployed
                          <span className="font-nunito-bold block mt-2 text-lg">
                            {/* ₹509,850.90 */} -
                          </span>
                        </h5>
                      </div>
                      <div className="col-span-1">
                        <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                          No. of strategies
                          <span className="font-nunito-bold block mt-2 text-lg">
                            {/* 5 */}-
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="w-full rounded-lg bg-white border border-cardBorder px-5 py-3">
                      <h5 className="text-sm font-nunito-medium text-black tracking-tight leading-none">
                        Total PNL:{" "}
                        <span className={`text-lg font-nunito-bold ${totalPL?.totalPLCombined?.toFixed(2)>= 0  ? 'text-green-500' : 'text-red-500'}  block mt-2`}>
                          ₹{totalPL?.totalPLCombined?.toFixed(2)}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* CHARTS */}
              <div className="pt-5">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
                      <CardBody className="p-0">
                        <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                          <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                            Net daily P&L
                          </h2>
                          <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                            Total net P&L for each trading day on the day it is
                            realized
                          </p>
                        </div>
                        <div className="p-0">
                          {netDailyBarGraphData?.length === 0 ? <p className="text-base py-5 px-5 font-nunito-regular text-greyText tracking-tight">
                            No Data found
                          </p> :
                            <NetDailyBarGraph data={netDailyBarGraphData} />}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-span-1">
                    <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
                      <CardBody className="p-0">
                        <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                          <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                            Cumulative P&L
                          </h2>
                          <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                            Your total account P&L accumulated over the course
                            of each trading day
                          </p>
                        </div>
                        <div className="p-0">
                          {netProfitLossGraphLabelsData?.length === 0 ? <p className="text-base py-5 px-5 font-nunito-regular text-greyText tracking-tight">
                            No Data found
                          </p> :
                            <NetProfitLossGraphLabels data={netProfitLossGraphLabelsData} />}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>

              {/* STRATEGY DETAILS */}
              <div className="pt-5">

                {/* Algo 1 - PCR Strategy */}
                <StrategyCard
                  name="Algo 1 - PCR Strategy"
                  date="-"
                  capital="-"
                  contracts="-"
                  plAmount={totalPL?.find((el) => el?.algo === 1)?.count}
                // plPercentage="0.65"
                />

                {/* Algo 2 - Intraday Option Sell */}
                <StrategyCard
                  name="Algo 2 - Intraday Option Sell"
                  date="-"
                  capital="-"
                  contracts="-"
                  plAmount={totalPL?.find((el) => el?.algo === 2)?.count}
                // plPercentage="0.65"
                />

                {/* Algo 3 - Intraday Options Buy */}
                <StrategyCard
                  name="Algo 3 - Intraday Options Buy"
                  date="-"
                  capital="-"
                  contracts="-"
                  plAmount={totalPL?.find((el) => el?.algo === 3)?.count}
                // plPercentage="0.65"
                />

                {/* Algo 4 - Intraday Stock Buy   */}
                <StrategyCard
                  name="Algo 4 - Intraday Stock Buy "
                  date="-"
                  capital="-"
                  contracts="-"
                  plAmount={totalPL?.find((el) => el?.algo === 4)?.count}
                // plPercentage="0.65"
                />

                {/* Algo 5 - Positional Option Sell */}
                <StrategyCard
                  name="Algo 5 - Positional Option Sell"
                  date="-"
                  capital="-"
                  contracts="-"
                  plAmount={totalPL?.find((el) => el?.algo === 5)?.count}
                // plPercentage="0.65"
                />

                {/* Algo 6 - Stock Buy */}
                <StrategyCard
                  name="Algo 6 - Stock Buy"
                  date="-"
                  capital="-"
                  contracts="-"
                  plAmount={totalPL?.find((el) => el?.algo === 6)?.count}
                // plPercentage="0.65"
                />

              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </section>
  );
};

export default Reports;
